import { Col, Form, InputNumber, Row, Select, Space } from "antd";
import { AktDateRangePicker } from "components/aktDatePicker";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  collectorsWithRolesSelector,
  useFetchAgencyWorkflowStates,
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchDebtorTagsQuery } from "features/tags/tagsAPI";
import styled from "styled-components";

const StyledHeader = styled.h4`
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const StyledSpan = styled.span`
  margin-bottom: 24px;
  display: block;
  font-size: 13px;
`;

const FilterTitle = styled.div`
  font-weight: 600;
  padding: 0 0 2px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin-right: 4px;
`;

export function DebtorsFiltersStep() {
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });
  const { data: constants = {} } = useFetchBackendConstantsQuery();
  const { data: debtorTags = [] } = useFetchDebtorTagsQuery();
  const { data: defaultWorkflowStates = [] } = useFetchAgencyWorkflowStates();
  const { data: productOfferings = [] } = useFetchProductOfferingsQuery();
  const { data: creditors = [] } = useFetchCreditorSummariesQuery();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  return (
    <>
      <StyledHeader>Filters</StyledHeader>
      <StyledSpan>Debtors matching these filter criteria will be selected.</StyledSpan>

      <Form.Item hidden name="type" />

      <FilterTitle>Debtor State</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "states"]} label="Include">
            <Select
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              filterOption={filterOption}
              options={(constants.states ?? []).map((state) => ({
                value: state.value,
                label: state.display,
              }))}
              placeholder="States..."
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeStates"]} label="Exclude">
            <Select
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              filterOption={filterOption}
              options={(constants.states ?? []).map((state) => ({
                value: state.value,
                label: state.display,
              }))}
              placeholder="States..."
            />
          </Form.Item>
        </Col>
      </Row>

      <StyledSpan>Debtors matching these filter criteria will be selected.</StyledSpan>

      <FilterTitle>Debtor Tags</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "tagIds"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Debtor Tags..."
              filterOption={filterOption}
              options={debtorTags.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeTagIds"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Debtor Tags..."
              filterOption={filterOption}
              options={debtorTags.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <StyledSpan>Debtors matching these filter criteria will be selected.</StyledSpan>

      <FilterTitle>Collectors Assigned</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "assigneeIds"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Collectors Assigned..."
              filterOption={filterOption}
              options={
                collectors && [
                  {
                    label: "Unassigned",
                    value: null,
                  },
                  ...(collectors.map((c) => ({
                    label: collectorFullName(c),
                    value: c.id,
                  })) ?? []),
                ]
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeAssigneeIds"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Collectors Assigned..."
              filterOption={filterOption}
              options={
                collectors && [
                  {
                    label: "Unassigned",
                    value: null,
                  },
                  ...(collectors.map((c) => ({
                    label: collectorFullName(c),
                    value: c.id,
                  })) ?? []),
                ]
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Clients</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "creditorIds"]} label="Include">
            <StyledSelect
              maxTagTextLength={20}
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Select Clients..."
              filterOption={filterOption}
              options={(creditors ?? []).map((creditor) => ({
                value: creditor.id,
                label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeCreditorIds"]} label="Exclude">
            <StyledSelect
              maxTagTextLength={20}
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Select Clients..."
              filterOption={filterOption}
              options={(creditors ?? []).map((creditor) => ({
                value: creditor.id,
                label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Statuses</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "statusCodes"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Status..."
              filterOption={filterOption}
              options={defaultWorkflowStates.map((state) => ({
                label: [state.code, state.name].join(" - "),
                value: state.code,
                title: state.description,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeStatusCodes"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Status..."
              filterOption={filterOption}
              options={defaultWorkflowStates.map((state) => ({
                label: [state.code, state.name].join(" - "),
                value: state.code,
                title: state.description,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Product Offerings</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "productOfferingIds"]} label="Include">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Product Offering..."
              filterOption={filterOption}
              options={productOfferings.map((productOffering) => ({
                label: productOffering.name,
                value: productOffering.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeProductOfferingIds"]} label="Exclude">
            <StyledSelect
              popupMatchSelectWidth={false}
              mode="multiple"
              allowClear
              placeholder="Product Offering..."
              filterOption={filterOption}
              options={productOfferings.map((productOffering) => ({
                label: productOffering.name,
                value: productOffering.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Turnover Date</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "turnoverDate"]} label="Include">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeTurnoverDate"]} label="Exclude">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
      </Row>
      <FilterTitle>Follow-up Date</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "followUpDate"]} label="Include">
            <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeFollowUpDate"]} label="Exclude">
            <AktDateRangePicker allowEmpty={[true, true]} picker="date" allowClear />
          </Form.Item>
        </Col>
      </Row>
      <FilterTitle>Last Call Date</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "lastCallDate"]} label="Include">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeLastCallDate"]} label="Exclude">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Last Payment Date</FilterTitle>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={["filters", "lastPaymentDate"]} label="Include">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={["filters", "excludeLastPaymentDate"]} label="Exclude">
            <AktDateRangePicker picker="date" allowClear />
          </Form.Item>
        </Col>
      </Row>

      <FilterTitle>Total Balance:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minAccountBalance"]}>
          <InputNumber placeholder="Min" controls={false} min="0" prefix="$" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "maxAccountBalance"]}>
          <InputNumber placeholder="Max" controls={false} min="0" prefix="$" step="0.01" />
        </Form.Item>
      </Space>

      <FilterTitle>Score:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minExternalScore"]}>
          <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "maxExternalScore"]}>
          <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
        </Form.Item>
      </Space>
    </>
  );
}
