import { EyeOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDatePicker } from "components/aktDatePicker";
import {
  useFetchCommunicationMethodsQuery,
  useFetchCommunicationMethodPreviewMutation,
} from "features/communicationMethodsTable/communicationMethodsAPI";
import {
  useCreateTaskMutation,
  useFetchDebtorAccountsQuery,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PreviewEmailModal from "./previewEmailModal";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function SendEmailModal({ title, open, onOk, onCancel }) {
  const [form] = useForm();
  const { debtorId } = useParams();
  const [createTask, { isLoading }] = useCreateTaskMutation();
  const { data: communicationMethods, isLoading: isCommunicationMethodsLoading } =
    useFetchCommunicationMethodsQuery();

  const [
    communicationMethodPreview,
    { isLoading: isCommunicationMethodPreviewLoading, data: previewData },
  ] = useFetchCommunicationMethodPreviewMutation();
  const { data: accounts, isLoading: isAccountsLoading } = useFetchDebtorAccountsQuery({
    debtorId,
  });

  const emailOptions = communicationMethods?.reduce((acc, method) => {
    if (method.medium !== "email") {
      return acc;
    }
    return [
      ...acc,
      {
        label: `${method.name} (${method.provider} ID: ${method.externalEmailTemplateId})`,
        value: method.id,
      },
    ];
  }, []);

  const onSubmit = async () => {
    const values = await form.validateFields();

    // Form post-transformation
    values.templateVariables = (values.templateVariables ?? []).reduce((acc, templateField) => {
      acc[templateField.fieldName] = templateField.fieldValue;
      return acc;
    }, {});

    const result = await createTask({ ...values, debtorId });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success(`Email(s) scheduled to send on ${values.scheduledDate}`);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const onPreview = async () => {
    try {
      const values = await form.validateFields();
      setIsPreviewModalVisible(true);
      const formattedDate = moment(values.scheduledDate, "MM/DD/YYYY").format("YYYY-MM-DD");
      await communicationMethodPreview({
        // We are only passing the first account id for now because we have implemented support
        // to display only one Email template preview.
        accountIds: [values.accountIds[0]],
        debtorId,
        dateToSend: formattedDate,
        communicationMethodId: values.communicationMethodId,
      });
    } catch (error) {
      message.error("Please fill out the form correctly before previewing");
    }
  };

  const handlePreviewCancel = () => {
    setIsPreviewModalVisible(false);
  };

  const disabledDate = (input) => {
    // Can not select days before today.
    return input && input < moment().startOf("day");
  };

  return (
    <>
      <Modal
        confirmLoading={isLoading}
        maskClosable={false}
        title={title}
        open={open}
        onOk={onSubmit}
        onCancel={onCancel}
        footer={[
          <Button key="preview" color="default" onClick={onPreview}>
            <EyeOutlined />
            Preview
          </Button>,
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            OK
          </Button>,
        ]}
      >
        <StyledForm layout="vertical" form={form}>
          <Form.Item
            label="Email Template"
            name="communicationMethodId"
            rules={[{ required: true, message: "Select an email template" }]}
          >
            <Select
              popupMatchSelectWidth={false}
              placeholder="Select one..."
              loading={isCommunicationMethodsLoading}
              options={emailOptions}
            />
          </Form.Item>
          <Form.Item
            label="Account(s)"
            name="accountIds"
            rules={[{ required: true, message: "Select an account" }]} // Only required for debt validation. This will need to be dynamic in the future when more letter types are supported
          >
            <Select
              mode="multiple"
              placeholder="Select one..."
              loading={isAccountsLoading}
              options={accounts?.map((account) => ({
                value: account.id,
                label: `ID: ${account.id}`,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="scheduledDate"
            label="Date to Send"
            rules={[{ required: true, message: "Select Date" }]}
            initialValue={moment().format(DATE_FORMAT)}
          >
            <AktDatePicker disabledDate={disabledDate} />
          </Form.Item>
        </StyledForm>
      </Modal>
      <PreviewEmailModal
        isLoading={isCommunicationMethodPreviewLoading}
        open={isPreviewModalVisible}
        onCancel={handlePreviewCancel}
        data={previewData}
      />
    </>
  );
}

export default SendEmailModal;
