export const SORT_ORDER = {
  ASCEND: "ascend",
  DESCEND: "descend",
};

export const TABLE_COLUMNS = {
  // # Account Worklist
  ACCOUNT_WORKLIST__ACCOUNT_ID: "account_worklist.account_id",
  ACCOUNT_WORKLIST__CLIENT_REFERENCE_ID: "account_worklist.client_reference_id",
  ACCOUNT_WORKLIST__EXTERNAL_IDS: "account_worklist.external_ids",
  ACCOUNT_WORKLIST__FOLLOW_UP_DATE: "account_worklist.follow_up_date",
  ACCOUNT_WORKLIST__DEBTOR: "account_worklist.debtor",
  ACCOUNT_WORKLIST__STATUS: "account_worklist.status",
  ACCOUNT_WORKLIST__CURRENT_BALANCE: "account_worklist.current_balance",
  ACCOUNT_WORKLIST__TURNOVER_DATE: "account_worklist.turnover_date",
  ACCOUNT_WORKLIST__EMPLOYMENT: "account_worklist.employment",
  ACCOUNT_WORKLIST__DEBT_TYPE: "account_worklist.debt_type",
  ACCOUNT_WORKLIST__LAST_PAYMENT_DATE: "account_worklist.last_payment_date",
  ACCOUNT_WORKLIST__JUDGMENT_DATE: "account_worklist.judgment_date",
  ACCOUNT_WORKLIST__CLOSE_DATE: "account_worklist.close_date",
  ACCOUNT_WORKLIST__IDENTIFIERS: "account_worklist.identifiers",
  ACCOUNT_WORKLIST__COLLECTOR_NAME: "account_worklist.collector_name",
  ACCOUNT_WORKLIST__LEGAL_STATUS: "account_worklist.legal_status",
  ACCOUNT_WORKLIST__CLIENT: "account_worklist.client",
  ACCOUNT_WORKLIST__EXTERNAL_SCORE: "account_worklist.external_score",
  ACCOUNT_WORKLIST__DEBTOR_ID: "account_worklist.debtor_id",
  ACCOUNT_WORKLIST__EXTERNAL_ID: "account_worklist.external_id",
  ACCOUNT_WORKLIST__LAST_LETTER_SENT_DATE: "account_worklist.last_letter_sent_at",
  ACCOUNT_WORKLIST__LATEST_LETTER_SENT_NAME: "account_worklist.latest_letter_sent_name",
  ACCOUNT_WORKLIST__NUMBER_OF_LETTER_SENT_TASKS: "account_worklist.number_of_letter_sent_tasks",
  ACCOUNT_WORKLIST__NUMBER_OF_CALLS: "account_worklist.number_of_calls",
  ACCOUNT_WORKLIST__LATEST_CALL_DATETIME: "account_worklist.latest_call_datetime",
  // ACCOUNT_WOrkLIST__INTELITECH_SCORES: "account_worklist.intelitech_scores",
  // ACCOUNT_WORKLIST__INTELITECH_I_SCORE: "account_worklist.intelitech_i_score",
  // ACCOUNT_WORKLIST__INTELITECH_MEDAL_SCORE: "account_worklist.intelitech_medal_score",
  // ACCOUNT_WORKLIST__INTELITECH_Q_SCORE: "account_worklist.intelitech_q_score",
  // ACCOUNT_WORKLIST__INTELITECH_R_SCORE: "account_worklist.intelitech_r_score",
  // ACCOUNT_WORKLIST__INTELITECH_FPG_VALUE: "account_worklist.intelitech_fpg_value",

  // # Creditor List
  // TODO: Add columns for creditor list

  // # Debtor Accounts
  DEBTOR_ACCOUNTS__IDENTIFIERS: "debtor_accounts.identifiers",
  DEBTOR_ACCOUNTS__TAGS: "debtor_accounts.tags",
  DEBTOR_ACCOUNTS__CURRENT_BALANCE: "debtor_accounts.current_balance",
  DEBTOR_ACCOUNTS__LAST_PAYMENT: "debtor_accounts.last_payment",
  DEBTOR_ACCOUNTS__CLIENT: "debtor_accounts.client",
  DEBTOR_ACCOUNTS__CURRENT_CLIENT: "debtor_accounts.current_client",
  DEBTOR_ACCOUNTS__ORIGINAL_CLIENT: "debtor_accounts.original_client",
  DEBTOR_ACCOUNTS__FOLLOW_UP_DATE: "debtor_accounts.follow_up_date",
  DEBTOR_ACCOUNTS__STATUS: "debtor_accounts.status",
  DEBTOR_ACCOUNTS__CO_DEBTOR_IDS: "debtor_accounts.co_debtor_ids",
  DEBTOR_ACCOUNTS__OTHER_KEY_DATES: "debtor_accounts.other_key_dates",
  DEBTOR_ACCOUNTS__COLLECTOR: "debtor_accounts.collector",
  DEBTOR_ACCOUNTS__FORWARDING_ENTITY: "debtor_accounts.forwarding_entity",
  DEBTOR_ACCOUNTS__USER_DEFINED_FIELDS: "debtor_accounts.user_defined_fields",
  DEBTOR_ACCOUNTS__ACTIONS: "debtor_accounts.actions",
  // DEBTOR_ACCOUNTS__INTELITECH_I_SCORE: "debtor_accounts.intelitech_i_score",
  // DEBTOR_ACCOUNTS__INTELITECH_MEDAL_SCORE: "debtor_accounts.intelitech_medal_score",
  // DEBTOR_ACCOUNTS__INTELITECH_Q_SCORE: "debtor_accounts.intelitech_q_score",
  // DEBTOR_ACCOUNTS__INTELITECH_R_SCORE: "debtor_accounts.intelitech_r_score",
  // DEBTOR_ACCOUNTS__INTELITECH_FPG_VALUE: "debtor_accounts.intelitech_fpg_value",
};

export const SIDER_ITEMS = {
  DEBTOR_SIDER__DEBTOR_NOTES: "debtor_sider.debtor_notes",
  DEBTOR_SIDER__DEBTOR_TAGS: "debtor_sider.debtor_tags",
  DEBTOR_SIDER__DEBTOR_INFO: "debtor_sider.debtor_info",
  DEBTOR_SIDER__PHONE_NUMBERS: "debtor_sider.phone_numbers",
  DEBTOR_SIDER__DEBTOR_ADDRESSES: "debtor_sider.debtor_addresses",
  DEBTOR_SIDER__DEBTOR_EMAILS: "debtor_sider.debtor_emails",
  DEBTOR_SIDER__DEBTOR_CONTACTS: "debtor_sider.debtor_contacts",
  DEBTOR_SIDER__DEBTOR_EMPLOYMENTS: "debtor_sider.debtor_employments",
  DEBTOR_SIDER__SIMILAR_DEBTORS: "debtor_sider.similar_debtors",

  CREDITOR_FILTERS__STATUSES: "creditor_filters.statuses",
  CREDITOR_FILTERS__CREDIT_REPORTING: "creditor_filters.credit_reporting",
  CREDITOR_FILTERS__CLIENT_TAGS: "creditor_filters.client_tags",
  CREDITOR_FILTERS__CLIENT_MERGE_GROUPS: "creditor_filters.client_merge_groups",
  CREDITOR_FILTERS__SALES_REP: "creditor_filters.sales_rep",

  ACCOUNT_FILTERS__QUEUES: "account_filters.queues",
  ACCOUNT_FILTERS__STATUSES: "account_filters.statuses",
  ACCOUNT_FILTERS__ACCOUNT_BALANCE: "account_filters.account_balance",
  ACCOUNT_FILTERS__FOLLOW_UP_DATE: "account_filters.follow_up_date",
  ACCOUNT_FILTERS__UPLOAD_DATE: "account_filters.upload_date",
  ACCOUNT_FILTERS__TURNOVER_DATE: "account_filters.turnover_date",
  ACCOUNT_FILTERS__LAST_CALL_DATE: "account_filters.last_call_date",
  ACCOUNT_FILTERS__CLIENT_MERGE_GROUPS: "account_filters.client_merge_groups",
  ACCOUNT_FILTERS__DEBTOR_LOCATION: "account_filters.debtor_location",
  ACCOUNT_FILTERS__CLIENT: "account_filters.client",
  ACCOUNT_FILTERS__LAWSUIT: "account_filters.lawsuit",
  ACCOUNT_FILTERS__DEBT_TYPE: "account_filters.debt_type",
  ACCOUNT_FILTERS__DEBTOR_TAGS: "account_filters.debtor_tags",
  ACCOUNT_FILTERS__ASSIGNEES: "account_filters.assignees",
  ACCOUNT_FILTERS__CLIENT_TAGS: "account_filters.client_tags",
  // ACCOUNT_FILTERS__INTELITECH_I_SCORE: "account_filters.intelitech_i_score",
  // ACCOUNT_FILTERS__INTELITECH_MEDAL_SCORE: "account_filters.intelitech_medal_score",
  // ACCOUNT_FILTERS__INTELITECH_Q_SCORE: "account_filters.intelitech_q_score",
  // ACCOUNT_FILTERS__INTELITECH_R_SCORE: "account_filters.intelitech_r_score",
  // ACCOUNT_FILTERS__INTELITECH_FPG_VALUE: "account_filters.intelitech_fpg_value",
  // ACCOUNT_FILTERS__INTELITECH_LITIGATION_SCORE: "account_filters.intelitech_litigation_score",
  // ACCOUNT_FILTERS__INTELITECH_N_SCORE: "account_filters.intelitech_n_score",
  // ACCOUNT_FILTERS__INTELITECH_O_SCORE: "account_filters.intelitech_o_score",
  // ACCOUNT_FILTERS__INTELITECH_M_SCORE: "account_filters.intelitech_m_score",
  // ACCOUNT_FILTERS__INTELITECH_X_SCORE: "account_filters.intelitech_x_score",
  // ACCOUNT_FILTERS__INTELITECH_Y_SCORE: "account_filters.intelitech_y_score",
  // ACCOUNT_FILTERS__INTELITECH_STATUTE_EXPIRATION: "account_filters.intelitech_statute_expiration",
};
