import { Input, InputNumber, Select } from "antd";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";

export function parseError(error) {
  if (error && error.response && error.response.data) {
    const resp = error.response.data;
    return resp.message;
  }

  return [error.message];
}

export function extractErrorMessage(error) {
  if (error && error.response && error.response?.data) {
    return error.response?.data?.message;
  }
  if (error.request) {
    return error.request;
  }

  return error.message;
}

/**
 * Capitalizes the first character of a string.
 * @param {string} input - The input string to capitalize.
 * @returns {string} - The capitalized string.
 */
export function capitalizeFirstLetter(input) {
  return input
    ?.toLowerCase()
    .split(" ")
    .map((each) => each.replace(/^./, (match) => match.toUpperCase()))
    .join(" ");
}

/**
 * Converts a string to title case.
 * @param {object} options - An object containing options for title case conversion.
 * @param {string} options.str - The string to convert.
 * @param {string} [options.delimiter=" "] - The delimiter to use for splitting the string into words.
 * @returns {string} The converted string.
 */
export function toTitleCase({ str, delimiter = " " }) {
  return str.split(delimiter).map(capitalizeFirstLetter).join(" ");
}

/**
 *  Converts a camel cased string to words separated by space.
 *  @param {string} camelCased - The string to convert.
 *  @returns {string} The converted string.
 */
export function camelToWords(camelCased = "") {
  const inWords = camelCased.replace(/([A-Z])/g, (match) => ` ${match}`).trim();
  return capitalizeFirstLetter(inWords);
}

export function formatDate(date) {
  // TODO: we pass in data from the backend as date strings without timezone info.  They are
  // already formatted according to the local timezone, so to prevent `toLocaleDateString` from
  // re-applying the timezone shift, we tell it that we want UTC time not local time.
  if (date) return new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });
  return null;
}

export function getFirstItem(couldBeArray) {
  if (Array.isArray(couldBeArray)) {
    if (couldBeArray.length > 0) return couldBeArray[0];
    return null;
  }
  // It's not an array
  return couldBeArray;
}

export function renderAddress(address, states) {
  if (!address) {
    return "";
  }
  const stateDisplay = states?.find((s) => s.value === address?.state)?.display;
  return [address?.address1, address?.address2, address?.city, stateDisplay]
    .filter((item) => !!item) // filter out undefined and empty strings
    .join(", ");
}

export function formatCurrency(amount) {
  return currency(amount, { precision: 2 }).format();
}

export const transformRequestParams = (params) => {
  // Sample usage: transformRequestParams({'country': ['PL', 'RU'], 'nacionality': 'FOO' })
  // Sample result: country=PL&country=RU&nacionality=FOO
  const keys = Object.keys(params);
  let options = "";

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === "object";
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element) => {
        options += `${key}=${element}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};

/**
 * Converts a string from camelCase to snake_case.
 * @param {string} str - The input string in camelCase format.
 * @returns {string} - The string converted to snake_case format.
 */
export function camelToSnakeCase(str) {
  return (
    str
      // Handle the transition from lowercase to uppercase or a digit
      .replace(/([a-z])([A-Z0-9])/g, "$1_$2")
      // Handle the transition between consecutive uppercase letters followed by a lowercase letter
      .replace(/([A-Z])([A-Z])(?=[a-z])/g, "$1_$2")
      .toLowerCase()
  );
}
/**
 * Converts a snake_case string to camelCase.
 * @param {string} str - The snake_case string to convert.
 * @returns {string} - The equivalent camelCase string.
 */
export function snakeToCamelCase(str) {
  return str.replace(/_([a-z0-9])/g, (match, p1) => p1.toUpperCase());
}

/**
 * Recursively renames object keys using a given renaming function.
 * @param {Object} obj - The object to rename keys for.
 * @param {Function} renameFunc - The function to use for renaming keys.
 * @returns {Object} - A new object with the renamed keys.
 */
export function deepRename(obj, renameFunc) {
  // We don't care about anything that isn't an object
  // Note: `typeof []` => "object" (!)
  // And even worse: `typeof null` => "object" (!!)
  if (typeof obj !== "object" || obj === null) return obj;

  // Arrays: just map each item over `deepRename`
  if (Array.isArray(obj)) {
    return obj.map((subObj) => deepRename(subObj, renameFunc));
  }

  // All that's left are non-null, non-array, plain old objects
  const newObj = {};
  Object.keys(obj).forEach((property) => {
    newObj[renameFunc(property)] = deepRename(obj[property], renameFunc);
  });
  return newObj;
}

// Adds key to arrays. Prevents key-related issues in the console
export function populateKey(data, getter = (i) => i.id) {
  const populateObject = (object) => {
    const key = getter(object);
    const keyObject = key ? { key } : {};
    return {
      ...object,
      ...keyObject,
    };
  };

  if (data === undefined) return;
  if (data.constructor === Array) {
    return data.map(populateObject);
  }
  if (typeof data === "object" && !!data) {
    return populateObject(data);
  }
  return data;
}

export function extractFilenameFromContentDispositionHeader(contentDispositionHeader) {
  const regularExpression = /filename="(.*)"$/gim;
  return regularExpression.exec(contentDispositionHeader)?.[1];
}

export function downloadFileBlob(blob, fileName) {
  const link = document.createElement("a");
  const url = window.URL ?? window.webkitURL;
  // @ts-ignore
  link.href = url.createObjectURL(blob);
  if (fileName) {
    link.download = fileName;
  }
  link.click();
  link.remove();
}

export function downloadFile(blob, meta) {
  if (blob.size === 0) {
    return null;
  }
  // Example, Content-Disposition: attachment; filename="hatteras_debt_validation_2023-03-01-2023-03-10.txt"
  const contentDispositionHeader = meta.response?.headers.get("Content-Disposition");
  const fileName = extractFilenameFromContentDispositionHeader(contentDispositionHeader);
  downloadFileBlob(blob, fileName);
}

export const downloadFileFromUrl = async (url, customFileName) => {
  const response = await fetch(url);
  const contentDispositionHeader = response.headers.get("content-disposition");
  // Check if there is an customFileName to use, otherwise extract it from the content disposition header
  const fileName =
    customFileName || extractFilenameFromContentDispositionHeader(contentDispositionHeader);
  const blob = await response.blob();
  downloadFileBlob(blob, fileName);
};

/**
 *
 let operations = {
   delete: undefined,
   foo:  {},
   assignToCollector: {id: 2},
   assignToCommunicationSequence: {id: undefined},
   assignToForwardingEntity: {id: undefined},
   changeStatus: {workflowStateId: undefined},
   sendLetter: {communicationMethodId: undefined}
 };

 extractNonEmptyFormEntries(operations);
 // {assignToCollector: {id: 2}}
 */

export const extractNonEmptyFormEntries = (values) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (!allLeavesAreUndefined(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});

  function allLeavesAreUndefined(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj === undefined;
    }

    return Object.values(obj).every((value) => allLeavesAreUndefined(value));
  }
};

export function parseCardExpirationDate(expirationDate) {
  const [monthStr, yearStr] = expirationDate.split("/");

  const month = parseInt(monthStr, 10);
  const year = parseInt(yearStr, 10) + 2000;

  if (
    Number.isNaN(month) ||
    month < 1 ||
    month > 12 ||
    Number.isNaN(year) ||
    year < 1 ||
    year > 9999
  ) {
    return [null, null];
  }
  return [month, year];
}

/**
 * Converts an array of id based objects to a map of id to object.
 * @param {Array} objs - The input array of objects.
 * @returns {Map} - The map of object IDs to objects.
 */
export function idToObjectMap(objs) {
  return objs.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});
}

export function booleanToYesNo(value) {
  return value ? "Yes" : "No";
}

/**
 *
 * @param {*} value - The value to format as a percentage.
 * @returns {string} - The formatted percentage.
 */
export function formatPercentage(value) {
  return `${currency(value, { precision: 4 }).multiply(100).value}%`;
}

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const udfToComponentMap = {
  integer: <InputNumber />,
  decimal: <InputNumber prefix="$" step="0.01" />,
  text: <Input.TextArea />,
  date: <AktDatePicker />,
  boolean: (
    <Select
      options={[
        { value: "", label: "Unassigned" },
        { value: true, label: "True" },
        { value: false, label: "False" },
      ]}
    />
  ),
};

const identity = (x) => x;
export const customFieldTypeFormatFunctions = {
  integer: identity,
  decimal: formatCurrency,
  text: identity,
  date: formatDate,
  boolean: (value) => {
    // The value currently comes both as string and as a boolean from the backend
    if (value === null || value === undefined) {
      return "";
    }
    if (typeof value === "string") {
      return value;
    }
    return value ? "True" : "False";
  },
};

/**
 * Converts customFieldSlugToValue object {slug: value} into [{value, field, slug}, ...] where field is the definition of a UDF field
 * and slug is the snakeCase slug of the field.
 *
 * Fields marked as important will be included in the result even if the value is null-ish, unless options.ignoreImportant is true.
 * If options.includeAll is true, all fields will be included in the result, this is useful for rendering a form for editing or doing customized show-hide logic.
 * Extra fields in customFieldSlugToValue are ignored.
 * The order of the {value, field} pairs in the result is the same as in the fieldsList.
 * @param {Object.<string, any>} customFieldSlugToValue the customFields of an entity, returned by fetching the entity
 * @param {Array.<{
 *  isImportant: boolean, name: string, slug: string, type: ("date" | "boolean" | "integer" | "text" | "decimal")
 * }>} fieldsList list of UDF fields that can potentially be applied to the entity
 * @param {{ignoreImportant?: boolean, includeAll?: boolean }} options
 * @returns {Array.<{
 *  value: any,
 *  slug: string, // the snakeCase slug of the field
 *  field: {isImportant: boolean, name: string, slug: string, type: ("date" | "boolean" | "integer" | "text" | "decimal"), [key: string]: any}
 * }>}
 */
export function getCustomFieldsItems(customFieldSlugToValue, fieldsList, options = {}) {
  customFieldSlugToValue = customFieldSlugToValue ?? {};
  fieldsList = fieldsList ?? [];
  const { ignoreImportant, includeAll } = options;
  return fieldsList
    .map((field) => {
      const slug = snakeToCamelCase(field.slug);
      const value = customFieldSlugToValue[slug];
      return { field, slug, value };
    })
    .filter(({ value, field }) => {
      if (
        !includeAll &&
        (value === null || value === undefined) &&
        (!field.isImportant || ignoreImportant)
      ) {
        return false;
      }
      return true;
    });
}
